<template>
  <!-- Sizes your content based upon application components -->
  <v-content>
    <!-- Provides content that integrated with vue router -->
    <transition name="slide-fade" mode="out-in">
      <router-view></router-view>
    </transition>
  </v-content>
</template>

<script>
export default {
  name: "Container"
};
</script>
